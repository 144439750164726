function resolve(doc) {
  try {
    if (doc.type === 'servicepage') return `/tjanster/${doc.uid}/`
    if (doc.type === 'blog_post') {
      return `/blogg/${doc.uid}/`
    }
    if (doc.type === 'blogcategorypage') {
      return `/blogg/${doc.uid}/`
    }
    if (doc.type === 'blogcategory') {
      return `/blogg/${doc.uid}/`
    }
    return `/${doc.uid}/`
  } catch (error) {
    console.error("Failed to resolve link.", doc, error)
  }
}

const linkResolver = ({ node, key, value }) => (doc) => {
  return resolve(doc)
};

export default linkResolver;

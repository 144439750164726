import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'

import { Typography } from '@material-ui/core'
import linkResolver from '../prismic/resolver/linkResolver'

const PreviewPage = (data) => {
  
  if (data.isPreview === false) return 'Not a preview!'

  return (
    <>
      <Typography variant="h1">Loading...</Typography>
    </>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'dagsverket', //TODO: add as .env
  linkResolver
})